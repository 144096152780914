import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFireDatabase } from "@angular/fire/database";
import { AngularFirestore, AngularFirestoreDocument, DocumentReference } from "@angular/fire/firestore";
import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular";
import firebase from "firebase/app";
import { Observable, of } from "rxjs";
import { catchError, switchMap, tap } from "rxjs/operators";
import { OrderPortalProduct } from "./model/product";
import { Company } from "./state/companies/company.model";
import {
  DeleteUserEvent,
  Event,
  QuotaReminder,
  ResetPassword,
  UpdateUser,
  UserCreate,
  UserDataUpdate
} from "./state/events/event.model";
import { EventsService } from "./state/events/events.service";
import { Role } from "./state/roles/role.model";
import { UserExtra } from "./state/users/user.model";
import * as LogRocket from "logrocket";
import { RoleService } from "./state/roles/role.service";
import { CompanyService } from "./state/companies/company.service";

@Injectable({
  providedIn: "root"
})
export class UserService {
  public trackedUserId?: string;

  public $products: Observable<OrderPortalProduct[]>;
  public $user: Observable<UserExtra>;
  public $role: Observable<Role>;
  public $company: Observable<Company>;

  constructor(
    private afAuth: AngularFireAuth,
    private router: Router,
    private db: AngularFirestore,
    private rtdb: AngularFireDatabase,
    private eventsService: EventsService,
    private roleService: RoleService,
    private companyService: CompanyService
  ) {
    this.$user = this.afAuth.authState.pipe(
      tap((user: firebase.User) => {
        return this.trackPresence(user);
      }),
      switchMap(user => {
        if (user) {
          Sentry.addBreadcrumb({ message: "User logged in" });
          return this.db
            .collection("users")
            .doc<UserExtra>(user.uid)
            .valueChanges();
        } else {
          Sentry.addBreadcrumb({ message: "No user yet" });
          return of(null);
        }
      }),
      tap((user: UserExtra) => {
        if (!user) {
          return;
        }

        if (this.trackedUserId !== user.uid) {
          LogRocket.identify(user.uid, {
            name: user.displayName,
            email: user.email,
            role: user.role,
            companyRole: user.companyRole?.path,
            quotaBalance: user.quotaBalance
          });
          this.trackedUserId = user.uid;
        }
      }),
      catchError(e => {
        Sentry.captureException(e);
        return of(null);
      })
    );

    this.$role = this.$user.pipe(
      switchMap(user => {
        if (user && user.companyRole) {
          return this.fromRef<Role>(user.companyRole);
        } else {
          return of(null);
        }
      }),
      tap((role: Role) => {
        Sentry.configureScope(scope => {
          if (role) {
            scope.setTag("role", role.name);
          }
        });
      }),
      catchError(e => {
        Sentry.captureException(e);
        return of(null);
      })
    );

    this.$products = this.$user.pipe(
      switchMap(user => {
        if (user && user.companyRole) {
          return this.getProductsForRole(user.companyRole);
        } else {
          return of([]);
        }
      }),
      catchError(e => {
        Sentry.captureException(e);
        return of([]);
      })
    );

    this.$company = this.$role.pipe(
      switchMap(role => {
        if (role) {
          return this.fromRef<Company>(role.company);
        } else {
          return of(null);
        }
      }),
      tap((company: Company) => {
        Sentry.configureScope(scope => {
          if (company) {
            scope.setTag("company", company.name);
          }
        });
      }),
      catchError(e => {
        Sentry.captureException(e);
        return of(null);
      })
    );
  }

  public getProductsForRole(role: DocumentReference): Observable<OrderPortalProduct[]> {
    return this.db
      .collection<OrderPortalProduct>("products", ref => ref.where("roles", "array-contains", role.id))
      .valueChanges({ idField: "id" });
  }

  fromRef<T>(ref: DocumentReference | string) {
    const path = typeof ref === "string" ? ref : ref.path;
    return this.db
      .doc<T>(path)
      .valueChanges()
      .pipe(
        catchError(e => {
          Sentry.captureException(e);
          return of(null);
        })
      );
  }

  currentUser(): Promise<firebase.User> {
    return this.afAuth.currentUser;
  }

  get authenticated(): boolean {
    return this.afAuth.authState !== null;
  }

  async trackPresence(user?: firebase.User) {
    if (!user) {
      return;
    }

    try {
      const sentryUser: Sentry.User = {
        email: user.email,
        username: user.displayName,
        id: user.uid
      };
      Sentry.configureScope(scope => {
        scope.setUser(sentryUser);
        scope.setTag("username", user.email);
        Sentry.setTag("status", "logged in");
      });

      if (this.trackedUserId != user.uid) {
        LogRocket.identify(user.uid, {
          name: user.displayName,
          email: user.email
        });
      }

      localStorage.setItem("user", JSON.stringify(sentryUser));

      // Sanitise the user id, so that there are no special characters
      const userStatusDatabaseRef = this.rtdb.database.ref(`/status/${user.uid.replace(/[\.#$,\[\]]/g, "-")}`);

      const isOfflineForDatabase: OnlineStatus = {
        state: "offline",
        uid: user.uid,
        email: user.email,
        last_changed: firebase.database.ServerValue.TIMESTAMP
      };

      const isOnlineForDatabase: OnlineStatus = {
        ...isOfflineForDatabase,
        state: "online"
      };

      this.rtdb.database.ref(".info/connected").on("value", snapshot => {
        if (snapshot.val() == false) {
          return;
        }

        Sentry.addBreadcrumb({ message: "Started connection status tracking" });
        userStatusDatabaseRef
          .onDisconnect()
          .set(isOfflineForDatabase)
          .then(() => {
            userStatusDatabaseRef.set(isOnlineForDatabase);
          });
      });

      await this.db
        .collection<UserExtra>("users")
        .doc(user.uid)
        .update({
          lastLoggedIn: new Date(),
          hasLoggedIn: true
        });
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
    }
  }

  public trackUserIfNotAlreadyLoggedIn(userId: string, username: string, email: string, groupPage: string = "") {
    try {
      if (!this.trackedUserId) {
        LogRocket.identify(userId, {
          name: username,
          email,
          groupPage
        });
      }
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
    }
  }

  public async updateUserData(user: Partial<UserExtra>): Promise<void> {
    const userRef: AngularFirestoreDocument<any> = this.db.collection<UserExtra>("users").doc(user.uid);

    const data: Partial<UserExtra> = {
      uid: user.uid,
      email: user.email,
      lastUpdated: new Date()
    };

    if (user.displayName) {
      data.displayName = user.displayName;
    }

    try {
      return await userRef.update(data);
    } catch (e) {
      console.warn(e);
      try {
        return await userRef.set(
          {
            ...data,
            displayName: user.displayName,
            photoURL: user.photoURL
          },
          { merge: true }
        );
      } catch (e) {
        Sentry.captureException(e);
      }
    }
  }

  async createUser(userCreate: UserCreate) {
    console.log("Creating User: ", userCreate);

    const createUserEvent: Event<UserCreate> = {
      type: "create-user",
      data: {
        ...userCreate,
        name: userCreate.name.trim(),
        lastName: userCreate.lastName.trim(),
        email: userCreate.email.trim()
      },
      createdBy: (await this.afAuth.currentUser).email,
      date: new Date()
    };

    return this.eventsService
      .add(createUserEvent)
      .then(() => {
        console.log("User created!");
      })
      .catch(e => {
        console.error("Failed to create user", e);
        Sentry.captureException(e);
        throw e;
      });
  }

  async deleteUser(email: string) {
    console.log("Deleting User: ", email);

    const deleteUserEvent: Event<DeleteUserEvent> = {
      type: "delete-user",
      data: {
        email: email.trim()
      },
      createdBy: (await this.afAuth.currentUser).email,
      date: new Date()
    };

    return this.eventsService
      .add(deleteUserEvent)
      .then(() => {
        console.log("User deleted! " + email);
      })
      .catch(e => {
        console.error("Failed to delete user", e);
        Sentry.captureException(e);
        throw e;
      });
  }

  async resetPasswordAndResend(email: string) {
    console.log("Resending welcome email: ", email);

    const event: Event<ResetPassword> = {
      createdBy: (await this.afAuth.currentUser).email,
      type: "reset-password",
      data: { email: email.trim() },
      date: new Date()
    };

    return this.eventsService
      .add(event)
      .then(() => {
        console.log("Welcome email resent!");
      })
      .catch(e => {
        console.error("Failed to resend welcome email", e);
        Sentry.captureException(e);
        throw e;
      });
  }

  async sendBulkQuotaReminderEmail(role: Role) {
    console.log("Sending bulk quota reminder email: ", role.name, role.id);

    const event: Event<QuotaReminder> = {
      createdBy: (await this.afAuth.currentUser).email,
      type: "quota-reminder",
      data: { roleId: role.id },
      date: new Date()
    };

    return this.eventsService
      .add(event)
      .then(() => {
        console.log("Quota Reminder email sent!");
      })
      .catch(e => {
        console.error("Failed to send bulk quota reminder email", e);
        Sentry.captureException(e);
        throw e;
      });
  }

  async bulkQuotaReset(role: Role) {
    console.log("Bulk-resetting quota for all users in: ", role.name, role.id);

    const event: Event<QuotaReminder> = {
      createdBy: (await this.afAuth.currentUser).email,
      type: "quota-reset",
      data: { roleId: role.id },
      date: new Date()
    };

    return this.eventsService
      .add(event)
      .then(() => {
        console.log("Quota Reset sent!");
      })
      .catch(e => {
        console.error("Failed to bulk quota reset", e);
        Sentry.captureException(e);
        throw e;
      });
  }

  async updateUserDataAdmin(email: string, updates: UserDataUpdate[]) {
    console.log("Updating user data: ", email, updates);

    const event: Event<UpdateUser> = {
      createdBy: (await this.afAuth.currentUser).email,
      type: "update-user",
      data: { email: email.trim(), updates },
      date: new Date()
    };

    return this.eventsService
      .add(event)
      .then(() => {
        console.log("Updated user data!");
      })
      .catch(e => {
        console.error("Failed to update user data", e);
        Sentry.captureException(e);
        throw e;
      });
  }

  createRole(role: Omit<Role, "id">) {
    console.log("Creating Role: ", role);

    return this.roleService
      .add({ ...role, name: role.name.trim() })
      .then(() => {
        console.log("Role created!");
      })
      .catch(e => {
        console.error("Failed to create role", e);
        Sentry.captureException(e);
        throw e;
      });
  }

  createCompany(company: Company) {
    console.log("Creating Company: ", company);

    return this.companyService
      .add({ ...company, name: company.name.trim(), logo: company.logo.trim() })
      .then(() => {
        console.log("Company created!");
      })
      .catch(e => {
        console.error("Failed to create company", e);
        Sentry.captureException(e);
        throw e;
      });
  }

  login(username: string, password: string) {
    return this.afAuth.signInWithEmailAndPassword(username.trim(), password.trim()).then(credential => {
      this.updateUserData(credential.user);
    });
  }

  signOut(): Promise<void> {
    return this.afAuth.signOut().then(() => {
      this.router.navigate(["/"]);
    });
  }
}

export interface OnlineStatus {
  state: "online" | "offline";
  uid: string;
  email: string;
  fullName?: string;
  quota?: number;
  last_changed: any;
}

import { Category } from "./../../../model/category";
import { Pdfs } from "../../../../assets/pdfs/pdfs";

export const schoolPricelists: Category[] = [
         {
           name: "Champion Bay Senior High School Pricelist",
           image: "schools/cbshs.png",
           ...Pdfs.CBSHS2025111224
         },
         {
           name: "Geraldton Senior High School Pricelist",
           image: "schools/gshs.png",
           ...Pdfs.GSHS2025
         },
         {
           name: "Nagle Catholic College Pricelist",
           image: "schools/nagle.png",
           ...Pdfs.NagleCollegePriceList2025
         },
         {
           name: "Bluff Point Primary Pricelist",
           image: "schools/bps.png",
           ...Pdfs.BluffPointUniformPriceList2025
         },
         {
           name: "Geraldton Primary Pricelist",
           image: "schools/gps2022.jpg",
           ...Pdfs.GPS2025111224
         },
         {
           name: "St Francis Xavier Primary Pricelist",
           image: "schools/sfx.png",
           ...Pdfs.SFXUniformPricelist2025251124
         },
         {
           name: "St Lawrence Primary Pricelist",
           image: "schools/stlawrence.png",
           ...Pdfs.StLawrence2025111224
         },
         {
           name: "Wandina Primary Pricelist",
           image: "schools/wandina.png",
           ...Pdfs.WandinaUniformCatalogue2025
         },
         {
           name: "Mount Tarcoola Primary Pricelist",
           image: "schools/mtp.png",
           ...Pdfs.MTPPriceList2025251124
         },
         {
           name: "Waggrakine Primary Pricelist",
           image: "schools/Waggrakine.png",
           ...Pdfs.WaggrakinePrimary2025121124
         },
         {
           name: "Yalgoo Primary Pricelist",
           image: "schools/Yalgoo.jpg",
           ...Pdfs.YalgooPS20232024
         },
         {
           name: "Binnu Primary School Pricelist",
           image: "schools/binnu-v1.png",
           ...Pdfs.BinnuPrimaryPriceList2024
         }
       ];

       export const tafePricelists: Category[] = [
         {
           name: "Central Regional TAFE",
           image: "schools/cr-tafe.jpg",
           ...Pdfs.CRTAFECommercialCookeryPriceList2025
         }
        ]
